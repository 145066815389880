.slot-time {
    display: flex;
    flex-direction: row;
    align-items: center;

    .time {
        padding: 0.4rem;
        font-size: 1.2rem;
        border-radius: 0.5rem;

        width: 80px;
        text-align: center;
        border: none;
        background-color: var(--element-background-idle);
        color: var(--element-foreground-idle);

        &.-invalid {
            color: hsl(0, 60%, 60%);
        }

        &.-playing {
            background-color: var(--element-background-playing);
            color: var(--element-foreground-playing);
        }

        &:focus {
            outline: none;
            outline: 2px solid var(--element-background-active);
        }
    }
}