:root {
  @media (prefers-color-scheme: light) {
    --element-background-active: hsl(205, 22%, 80%);
    --element-background-playing: hsl(156, 90%, 58%);
    --element-foreground-playing: hsl(156, 90%, 20%);
    --element-background-idle: hsl(156, 5%, 92%);
    --element-foreground-idle: rgb(97, 107, 103);
    --element-abort: hsl(15, 90%, 60%);
    --text-color-bright: hsl(0, 0%, 95%);
    --drop-area-over: hsl(205, 100%, 69%);

    --element-background-playlist-idle: rgba(209, 211, 210, 0.4);
    --element-foreground-playlist-idle: rgba(97, 107, 103, 1.0);
    --element-background-playlist-playing: hsla(156, 100%, 70%, 0.4);
    --element-foreground-playlist-playing: hsl(156, 100%, 15%);

    --element-background-playlist-with-schedule: hsl(174, 100%, 30%);
    --element-foreground-playlist-with-schedule: hsl(200, 90%, 100%);
  }

  @media (prefers-color-scheme: dark) {
    background-color: hsl(0, 0%, 12%);
    --element-background-active: hsl(205, 22%, 60%);
    --element-background-playing: hsl(156, 80%, 30%);
    --element-foreground-playing: hsl(156, 80%, 95%);
    --element-background-idle: hsl(156, 5%, 30%);
    --element-foreground-idle: hsl(156, 5%, 95%);
    --element-abort: hsl(15, 90%, 60%);
    --text-color-bright: hsl(0, 0%, 95%);
    --drop-area-over: hsl(205, 100%, 40%);

    --element-background-playlist-idle: rgba(209, 211, 210, 0.4);
    --element-foreground-playlist-idle: hsl(156, 5%, 95%);
    --element-background-playlist-playing: hsla(156, 100%, 60%, 0.4);
    --element-foreground-playlist-playing: hsl(156, 100%, 100%);

    --element-background-playlist-with-schedule: hsl(156, 90%, 30%);
    --element-foreground-playlist-with-schedule: hsl(156, 90%, 100%);
  }

  --top-playlist-bar-height-without-padding: 2em;
  --top-playlist-padding: 0.5em;
  --top-playlist-bar-height: calc(2 * var(--top-playlist-bar-height-without-padding) + var(--top-playlist-padding));

  --max-width: 600px;
  --list-element-padding: 0.3rem;
  --list-element-margin: 0.15rem;
  --list-element-font-size: 0.9rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}