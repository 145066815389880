.drop-area {
    position: relative;

    margin-top: -10px;
    margin-bottom: -10px;
    width: 90%;
    height: 20px;
    z-index: 10;

    scale: 1.2;

    &.-over {
        background-color: var(--drop-area-over);
    }
}