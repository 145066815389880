.horizontal-container {
    margin: var(--list-element-margin);
    display: grid;
    grid-template-columns: 95% auto;
}

.song-element {
    padding: var(--list-element-padding);
    border-radius: 0.5rem;
    background-color: var(--element-background-idle);
    color: var(--element-foreground-idle);

    transition: scale background-color color;
    transition-duration: 0.2s;

    display: grid;
    grid-template-columns: auto 50px;
    align-items: center;
    font-size: var(--list-element-font-size);

    cursor: grab;

    &:hover {
        scale: 1.02;
    }

    &.-playing {
        background-color: var(--element-background-playing);
        color: var(--element-foreground-playing);
    }

    &.-dragging {
        scale: 0.98;
        filter: brightness(0.8);
    }
    
    >.name {
        border: none;

        text-align: left;

        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    >.duration {
        border: none;

        text-align: right;
        filter: brightness(1.5);
    }
}