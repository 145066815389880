.schedule-slot {
    display: grid;
    grid-template-columns: 25% auto;

    >.tracks {
        --horizontal-padding: 2rem;
        padding-bottom: var(--horizontal-padding);
        min-height: 3rem;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .empty {
            color: hsl(0, 0%, 50%);
            font-size: 1.2rem;
            text-align: center;
            font-style: italic;
        }
    }
}

.time-line {
    display: flex;
    flex-direction: column;
    align-items: center;

    >.bottom {
        margin-top: auto;
    }

    >.separatorline {
        height: 100%;
        width: 0px;
        border-right: 4px dotted var(--element-background-idle);

        &.-playing {
            border-color: var(--element-background-playing);
        }
    }
}