.remove-button {
    scale: 0.0;
    --button-size: 1.3rem;
    --button-background: var(--element-abort);

    background-color: var(--button-background);
    color: var(--text-color-bright);

    text-align: center;
    vertical-align: middle;
    padding: 0px;

    border-radius: 30%;
    border: none;

    position: relative;
    right: calc(var(--button-size) * -0.7);
    top: calc(var(--button-size) * -0.5);

    width: var(--button-size);
    height: var(--button-size);

    /* In order to make the button not take up space */
    margin-left: calc(var(--button-size) * -1);

    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }

    transition: scale;
}

*:hover > .remove-button {
    scale: 1.0;
    transition-delay: 0s;
    transition-duration: 0s;
}

*:not(:hover) > .remove-button {
    transition-delay: 2s;
    transition-duration: 0.2s;
}